import React from "react";

const personalInfoContent = [
    { meta: "first name", metaInfo: "Alejandro Asor" },
    { meta: "last name", metaInfo: "Corrales Gomez" },
    { meta: "phone", metaInfo: "+57 3142710159" },
    { meta: "languages", metaInfo: "English, Spanish" },
    { meta: "linkedin", link: "https://www.linkedin.com/in/aacg/", show: "aacg" },
    { meta: "github", link: "https://github.com/AlejandroAsor", show: "AlejandroAsor" },
    { meta: "Email", metaInfo: "alejandroasor@corralesgomez.com" },
];

const PersonalInfo = () => {
    return (
        <ul className="about-list list-unstyled open-sans-font">
            {personalInfoContent.map((val, i) => (
                <li key={i}>
                    <span className="title">{val.meta}: </span>
                    <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {val.metaInfo}
          </span>
                    {val.link && (
                        <a href={val.link} target="_blank" rel="noopener noreferrer">
                            {val.show}
                        </a>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default PersonalInfo;
