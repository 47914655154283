import React from "react";

const educationContent = [
  {
    year: "2021 - Present",
    degree: "Systems Engineer",
    institute: "EAN University",
    details: `Currently in my 3th semester of 9 with a GPA of 4.7 out of 5, studying towards a degree in Systems Engineering at EAN University.`,
  },
  {
    year: "2021 - Present",
    degree: "Software Engineer",
    institute: "Corporación Universitaria Iberoamericana",
    details: `Currently in my 6th semester of 8 with a GPA of 4.8 out of 5, studying towards a degree in Software Engineering at Corporación Universitaria Iberoamericana.`,
  },
  {
    year: "2022 - 2023",
    degree: "Backend Developer",
    institute: "Henry Bootcamp",
    details: ``,
  },
  {
    year: "2021 - 2022",
    degree: "Systems Technician",
    institute: "Politécnico Mayor",
    details: ``,
  }
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
