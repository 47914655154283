import React from "react";

const experienceContent = [
  {
    year: "2023",
    position: "Backend Developer",
    compnayName: "UnBardo Design - SoyHenry",
    details: `As a Backend Developer at UnBardo Design, I created a successful e-commerce platform that exceeded the client's expectations. Focusing on security, functionality, design, and usability, the platform provided an exceptional user experience. Features included tools for easily finding products, detailed product information, a contact section, and the ability to calculate shipping costs. The admin panel allowed administrators to view store statistics and create new products. I successfully delivered an attractive and highly functional platform that met the needs of both the client and users.`,
  },
  {
    year: "2022 - 2023",
    position: "Backend Developer -  Teaching Assistant",
    compnayName: "SoyHenry",
    details: `In my role as a Backend Teaching Assistant at the Bootcamp, I guided and supported students throughout their educational journey, placing a strong emphasis on the development of soft skills. 
    I fostered teamwork and pair programming to encourage collaboration, as well as conflict resolution and shared leadership. Additionally, I provided individualized assistance to improve communication, adaptability, and empathy when solving exercises and optimizing Bootcamp processes.

I coordinated team-building activities, enhancing creativity and the ability to work under pressure, and facilitated effective communication to cultivate a strong sense of community among students. My approach was always focused on providing a comprehensive educational experience that not only covered technical learning but also strengthened essential soft skills for success in the professional realm.`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
