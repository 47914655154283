import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "../../../src/assets/scss/main/skills.scss";

const programmingLanguages = [
    { skillClass: "p89", skillName: "Java", imageUrl: "https://cdn.iconscout.com/icon/free/png-512/java-25-226002.png?f=avif&w=512" },
    { skillClass: "p66", skillName: "Python", imageUrl: "https://cdn.iconscout.com/icon/free/png-512/python-2-226051.png?f=avif&w=512" },
];

const databases = [
    { skillClass: "p50", skillName: "MySQL", imageUrl: "https://cdn.iconscout.com/icon/free/png-512/mysql-3521596-2945040.png?f=avif&w=512" },
    { skillClass: "p65", skillName: "MongoDB", imageUrl: "https://cdn.iconscout.com/icon/free/png-512/mongodb-5-1175140.png?f=avif&w=512" },
    { skillClass: "p45", skillName: "PostgreSQL", imageUrl: "https://cdn.iconscout.com/icon/free/png-512/postgresql-11-1175122.png?f=avif&w=512" },
];

const frameworks = [
    { skillClass: "p50", skillName: "Django", imageUrl: "https://cdn.iconscout.com/icon/free/png-512/django-11-1175036.png?f=avif&w=512" },
    { skillClass: "p50", skillName: "Spring", imageUrl: "https://img.icons8.com/color/512/spring-logo.png" },
];


const OperatingSystems = [
    { skillClass: "p50", skillName: "Linux", imageUrl: "https://cdn.iconscout.com/icon/free/png-512/linux-3521549-2944967.png?f=avif&w=512" },
    { skillClass: "p50", skillName: "MacOS", imageUrl: "https://cdn.iconscout.com/icon/free/png-512/macos-3-225413.png?f=avif&w=512" },
    { skillClass: "p50", skillName: "Windows", imageUrl: "https://cdn.iconscout.com/icon/free/png-512/windows-221-1175066.png?f=avif&w=512" },
];


const Skills = () => {
    const tabContent = (data) => (
        <div className="row align-items-center justify-content-center">
            {data.map((val, i) => (
                <div className="col-6 col-md-3 mb-3 mb-sm-5 d-flex justify-content-center" key={i}>
                    <div className={`skill-card ${val.skillClass}`}>
                        <img src={val.imageUrl} alt={val.skillName} />
                        {/*<div className="skill-name">*/}
                        {/*    <span>{val.skillName}</span>*/}
                        {/*</div>*/}
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <Tabs>
            <TabList className="category-tabs">
                <Tab className="category-tab">Programming Languages</Tab>
                <Tab className="category-tab">Databases</Tab>
                <Tab className="category-tab">Frameworks</Tab>
                <Tab className="category-tab">Operating Systems</Tab>
            </TabList>

            <TabPanel>{tabContent(programmingLanguages)}</TabPanel>
            <TabPanel>{tabContent(databases)}</TabPanel>
            <TabPanel>{tabContent(frameworks)}</TabPanel>
            <TabPanel>{tabContent(OperatingSystems)}</TabPanel>
        </Tabs>
    );
};

export default Skills;
